import client from 'libs/HttpClient';

class UserMasterService {
  static viewUser(params) {
    return client.get(`user_master/get`, { params });
  }
  static viewUserBusiness(params) {
    return client.get(`user_master/list_business_details`, { params });
  }
  static updateUser(request) {
    return client.put(`user_master/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static userDelete(params) {
    return client.delete(`user_master/delete`, { params });
  }
  static listUser({ params }) {
    return client.get(`user_master/list`, { params });
  }
  static statusChangeUser(request) {
    return client.patch(`user_master/status_change_user`, request);
  }
  static statusChangeAdmin(request) {
    return client.patch(`user_master/status_change_admin`, request);
  }
  static useStatusChangeBusinessUser(request) {
    return client.put(`user_master/business_status_change_user`, request);
  }
}

export { UserMasterService };

import {
  SETTING_HOME,
  HOME_BANNER,
  HOME_DOWNLOAD,
  HOME_FOOTER,
  HOME_JOB_POST,
  HOME_SEO,
  SETTING_ABOUT,
  ABOUT_BANNER,
  ABOUT_MISSION,
  ABOUT_OUR_TEAM,
  ABOUT_SEO,
  ABOUT_VISION,
  ABOUT_WHO_WE_ARE,
  SETTING,
  GENERAL,
  SETTING_CONTACT_US,
  CONTACT_SETTING,
  FAQ_SETTING,
  SETTING_FAQ,
  MAIL_SETTING,
  CHANGE_PASSWORD,
  EDIT_PROFILE,
} from 'config';
import { POST_SEO_SETTING } from './routes';

export const aboutNavItems = [
  {
    path: SETTING_ABOUT + ABOUT_WHO_WE_ARE,
    translationKey: 'page.general_setting_tab_about_who_we_are_section',
  },
  {
    path: SETTING_ABOUT + ABOUT_MISSION,
    translationKey: 'page.general_setting_tab_about_our_mission_section',
  },
  {
    path: SETTING_ABOUT + ABOUT_VISION,
    translationKey: 'page.general_setting_tab_about_our_vision_section',
  },
  {
    path: SETTING_ABOUT + ABOUT_OUR_TEAM,
    translationKey: 'page.general_setting_tab_about_our_team_section',
  },
  {
    path: SETTING_ABOUT + ABOUT_SEO,
    translationKey: 'page.general_setting_tab_about_seo_section',
  },
];

export const contactNavItems = [
  {
    path: SETTING_CONTACT_US + CONTACT_SETTING,
    translationKey: 'page.general_setting_tab_contact_us_section',
  },
];

export const faqNavItems = [
  {
    path: SETTING_FAQ + FAQ_SETTING,
    translationKey: 'page.general_setting_tab_faq_section',
  },
];

export const homeNavItems = [
  {
    path: SETTING_HOME + HOME_FOOTER,
    translationKey: 'page.general_setting_tab_home_footer_section',
  },
  {
    path: SETTING_HOME + HOME_SEO,
    translationKey: 'page.general_setting_tab_home_seo_info_section',
  },
];

export const mailNotificationNavItems = [
  {
    path: SETTING + GENERAL,
    translationKey: 'page.general_setting_tab_general_setting',
  },
  {
    path: SETTING + MAIL_SETTING,
    translationKey: 'page.general_setting_tab_email_and_notification_setting',
  },
  {
    path: SETTING + POST_SEO_SETTING,
    translationKey: 'page.general_setting_tab_post_seo_setting',
  },
];

export const accountNavItems = [
  {
    path: EDIT_PROFILE,
    translationKey: 'page.edit_profile',
  },
  {
    path: CHANGE_PASSWORD,
    translationKey: 'page.edit_change_password',
  },
];

import client from 'libs/HttpClient';

class AdminPricingService {
  static getList({ params }) {
    return client.get(`pricing/list`, { params });
  }
  static add(request) {
    return client.post(`pricing/add`, request);
  }
  static get(params) {
    return client.get(`pricing/view`, { params });
  }
  static edit(request) {
    return client.put(`pricing/edit`, request);
  }
  static delete(params) {
    return client.delete(`pricing/delete`, { params });
  }
  static changeStatus(request) {
    return client.put(`pricing/status-change`, request);
  }
  static getStates(request) {
    return client.get(`pricing/list_state`, request);
  }
  static getCities(request) {
    return client.get(`pricing/list_cities`, request);
  }
}

export { AdminPricingService };

import { AdminHomeService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetHomeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['home_counts'],
    () => {
      return AdminHomeService.homeCount();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetHomeAdsDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['ads_details', [params]],
    () => {
      return AdminHomeService.adsDetails({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetHomeUserDetails = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['users_details', [params]],
    () => {
      return AdminHomeService.usersDetails({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetHomePaymentDetails = (params, onSuccess, onError = onDefaultError) => {
  console.log('params: ', params);
  return useQuery(
    ['payments_details', [params]],
    () => {
      return AdminHomeService.paymentDetails({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetHomeData, useGetHomeAdsDetails, useGetHomeUserDetails, useGetHomePaymentDetails };

import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { settingData } from 'store';
import { Header, Sidebar } from 'common';
import userIcon from 'assets/images/dashboard.png';
const LogoUrlFront = () => {
  const getSettingData = useSelector(settingData);

  return (
    <LazyLoadImage
      key={
        getSettingData.home_page_general_header_logo
          ? getSettingData.home_page_general_header_logo
          : userIcon
      }
      placeholderSrc={userIcon}
      src={
        getSettingData.home_page_general_header_logo
          ? getSettingData.home_page_general_header_logo
          : userIcon
      }
      alt="collegely-logo"
      width={'100%'}
      className="nav-brand"
    />
  );
};
const PrivateLayout = (props) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = isActive ? 'overly bg-white' : 'bg-white';
  const bypassUrl = window.location.href.split('/').pop();

  return (
    <>
      <div {...props}>
        <Header toggleClass={toggleClass} t={t} />
        <div id={`${bypassUrl != 'chat' ? 'wrapper' : ''}`} className={isActive ? 'toggled' : ''}>
          {bypassUrl != 'chat' && <Sidebar toggleClass={toggleClass} active={props.active} t={t} />}
          <section
            id="content-wrapper"
            className={`${bypassUrl == 'chat' && 'p-0 px-3'}`}
            onClick={isActive ? toggleClass : null}>
            <Row>
              <Col lg={12}>{props.children}</Col>
            </Row>
          </section>
        </div>
      </div>
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout, LogoUrlFront };

export const commonOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};

export const userStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_user_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};
export const adminStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_admin_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};
export const userAccessOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_access_name')}` },
    { value: 2, label: `${t('page.users_user_type_user')}` },
    { value: 3, label: `${t('page.users_user_type_business_user')}` },
  ];
};
export const userSignupTypeOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_signup_type_name')}` },
    { value: 1, label: `${t('page.users_signup_type_normal')}` },
    { value: 2, label: `${t('page.users_signup_type_gmail')}` },
    // { value: 3, label: `${t('page.users_signup_type_three')}` },
    // { value: 4, label: `${t('page.users_signup_type_four')}` },
  ];
};

export const categoryTypeOption = (t) => {
  return [
    { value: '', label: `${t('page.category_category_type')}` },
    { value: 1, label: `${t('page.category_parent')}` },
    { value: 2, label: `${t('page.category_sub')}` },
    { value: 3, label: `${t('page.category_individual')}` },
  ];
};
export const attributesTypeOption = (t) => {
  return [
    { value: '', label: `${t('page.attributes_type')}` },
    { value: 1, label: t('page.attribute_type_text_box') },
    { value: 2, label: t('page.attribute_type_text_area') },
    { value: 3, label: t('page.attribute_type_drop_down') },
    { value: 4, label: t('page.attribute_type_check_box') },
    { value: 5, label: t('page.attribute_type_google_location_search') },
    { value: 6, label: t('page.attribute_type_drop_pin_on_map') },
    { value: 7, label: t('page.attribute_type_external_link') },
    { value: 8, label: t('page.attribute_type_salary_price') },
  ];
};

export const transactionOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.success_status_name')}` },
    { value: 2, label: `${t('page.failed_status_name')}` },
  ];
};

export const postApprovedOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.approved_status_name')}` },
    { value: 2, label: `${t('page.reject_status_name')}` },
    { value: 3, label: `${t('page.pending_status_name')}` },
  ];
};

export const reportTypeFilter = (t) => {
  return [
    { value: '', label: `${t('page.select_report_type')}` },
    { value: 1, label: `${t('page.profile_type')}` },
    { value: 2, label: `${t('page.advertisement_type')}` },
    { value: 3, label: `${t('page.chat_type')}` },
  ];
};

// CommonHelmet.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { settingData } from 'store';

const CommonHelmet = ({ title, description }) => {
  const getSettingData = useSelector(settingData);
  const descriptions = description || getSettingData.home_page_general_seo_description;
  const titles =
    getSettingData.home_page_general_seo_title + ' | ' + title ||
    t('page.home_page_general_seo_title');

  return (
    <Helmet>
      <title>{titles}</title>
      <meta name="title" content={titles} />
      <meta name="description" content={descriptions} />
      <meta name="og:title" content={titles} />
      <meta name="og:description" content={descriptions} />
      {getSettingData.home_page_general_favicon_logo && (
        <link rel="icon" href={getSettingData.home_page_general_favicon_logo} />
      )}
    </Helmet>
  );
};

export { CommonHelmet };

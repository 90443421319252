import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ABOUT_SEO } from 'config';
import { ABOUT_WHO_WE_ARE } from 'config';
import { ABOUT_MISSION } from 'config';
import { ABOUT_VISION } from 'config';
import { ABOUT_OUR_TEAM } from 'config';

// Lazy load components
const AboutSeoSetting = React.lazy(() => import('./AboutSeoSettingPage'));
const AboutWhoWeAreSetting = React.lazy(() => import('./AboutWhoWeAreSettingPage'));
const AboutOurMissionSetting = React.lazy(() => import('./AboutOurMissionSettingPage'));
const AboutOurVisionSetting = React.lazy(() => import('./AboutOurVisionSettingPage'));
const AboutOurTeamSetting = React.lazy(() => import('./AboutOurTeamSettingPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [ABOUT_SEO]: 'Collegely | About Seo Settings',
  [ABOUT_WHO_WE_ARE]: 'Collegely | Who We Are Section',
  [ABOUT_MISSION]: 'Collegely | Our Mission Section',
  [ABOUT_VISION]: 'Collegely | Our Vision Section',
  [ABOUT_OUR_TEAM]: 'Collegely | Our Team Section',
  // Add additional mappings here if needed
};

const AboutRoutes = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    return routeTitleMapping[path] || 'Collegely | About'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route path={ABOUT_SEO} element={<AboutSeoSetting t={t} />} />
        <Route path={ABOUT_WHO_WE_ARE} element={<AboutWhoWeAreSetting t={t} />} />
        <Route path={ABOUT_MISSION} element={<AboutOurMissionSetting t={t} />} />
        <Route path={ABOUT_VISION} element={<AboutOurVisionSetting t={t} />} />
        <Route path={ABOUT_OUR_TEAM} element={<AboutOurTeamSetting t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

export default AboutRoutes;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT, VIEW } from 'config';

const BoostsList = React.lazy(() => import('./ListBoosts'));
const AddBoosts = React.lazy(() => import('./AddBoost'));
const ViewBoosts = React.lazy(() => import('./ViewBoosts'));
const EditBoosts = React.lazy(() => import('./EditBoosts'));

const BoostsRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<BoostsList t={t} />} />
      <Route exact path={ADD} element={<AddBoosts t={t} />} />
      <Route exact path={VIEW + ':boost_post_id'} element={<ViewBoosts t={t} />} />
      <Route exact path={EDIT + ':boost_post_id'} element={<EditBoosts t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
BoostsRoute.propTypes = {
  t: PropTypes.func,
};
export default BoostsRoute;

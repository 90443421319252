import client from 'libs/HttpClient';

class AdminStateCityService {
  static listStateCity({ params }) {
    return client.get(`state-city/list`, { params });
  }
  static addStateCity(request) {
    return client.post(`state-city/add`, request);
  }
  static viewStateCity(params) {
    console.log('params: ', params);
    return client.get(`state-city/get`, { params });
  }
  static updateStateCity(request) {
    return client.put(`state-city/edit`, request);
  }
  static deleteStateCity(params) {
    return client.delete(`state-city/delete`, { params });
  }
  static statusChangeStateCity(request) {
    return client.put(`state-city/updateStatus`, request);
  }
}
export { AdminStateCityService };

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { FAQ_SETTING } from 'config';

const FaqSetting = React.lazy(() => import('./FaqSettingPage'));

const FaqRoutes = ({ t }) => (
  <Routes>
    <Route path={FAQ_SETTING} element={<FaqSetting t={t} />} />;
  </Routes>
);

export default FaqRoutes;

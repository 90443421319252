import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { LIST, EDIT, VIEW } from 'config';

const ListUserPage = React.lazy(() => import('./ListUserPage'));
const EditUserPage = React.lazy(() => import('./EditUserPage'));
const ViewUserPage = React.lazy(() => import('./ViewUserPage'));

// Define title mappings for different paths
const routeTitleMapping = {
  [LIST]: 'Collegely | User List',
  [EDIT]: 'Collegely | Edit User Profile',
  [VIEW]: 'Collegely | User Details',
};

const UserRoutes = ({ t }) => {
  const location = useLocation();

  // Determine the title based on the current pathname
  const getTitleForPath = (path) => {
    // Exact match for specific routes
    if (path.includes(LIST)) return routeTitleMapping[LIST];
    if (path.includes(EDIT)) return routeTitleMapping[EDIT];
    if (path.includes(VIEW)) return routeTitleMapping[VIEW];
    return 'Collegely | User'; // Default title
  };

  const pageTitle = getTitleForPath(location.pathname);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListUserPage t={t} />} />
        <Route exact path={EDIT + ':user_id'} element={<EditUserPage t={t} />} />
        <Route exact path={VIEW + ':user_id'} element={<ViewUserPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

UserRoutes.propTypes = {
  t: PropTypes.func,
};

export default UserRoutes;

import { AdminPricingService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetPricing = (params, onSuccess, onError) => {
  return useQuery(
    ['pricing_list', [params]],
    () => {
      return AdminPricingService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddPrice = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminPricingService.add, {
    onSuccess,
    onError,
  });
};
const useDeletePrice = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminPricingService.delete, {
    onSuccess,
    onError,
  });
};
const useChangePricingStatus = (onSuccess, onError) => {
  return useMutation(AdminPricingService.changeStatus, {
    onSuccess,
    onError,
  });
};
const useEditPrice = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminPricingService.edit, {
    onSuccess,
    onError,
  });
};
const useGetPrice = (params, onSuccess, onError) => {
  return useQuery(
    ['pricing_detail', [params]],
    () => {
      return AdminPricingService.get({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetStates = (onSuccess, onError) => {
  return useQuery(
    ['get_states'],
    () => {
      return AdminPricingService.getStates();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCities = (onSuccess, onError) => {
  return useQuery(
    ['get_cities'],
    () => {
      return AdminPricingService.getCities();
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetPricing,
  useAddPrice,
  useDeletePrice,
  useChangePricingStatus,
  useEditPrice,
  useGetPrice,
  useGetStates,
  useGetCities,
};

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CONTACT_SETTING } from 'config';

const ContactUsSetting = React.lazy(() => import('./ContactUsSettingPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [CONTACT_SETTING]: 'Collegely | Contact Us Section',
};

const ContactRoutes = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    return routeTitleMapping[path] || 'Collegely | Contact Us'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route path={CONTACT_SETTING} element={<ContactUsSetting t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

export default ContactRoutes;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT, VIEW } from 'config';

const BoostsList = React.lazy(() => import('./ListPricing'));
const AddPrice = React.lazy(() => import('./AddPrice'));
const ViewBoosts = React.lazy(() => import('./ViewBoosts'));
const EditPrice = React.lazy(() => import('./EditPrice'));

const PricingRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<BoostsList t={t} />} />
      <Route exact path={ADD} element={<AddPrice t={t} />} />
      <Route exact path={VIEW + ':price_id'} element={<ViewBoosts t={t} />} />
      <Route exact path={EDIT + ':price_id'} element={<EditPrice t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PricingRoute.propTypes = {
  t: PropTypes.func,
};
export default PricingRoute;

import { AdminTransactionService } from 'api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const defaultError = (error) => {
  toast.error(error.message);
};

const useListTransactions = (params, onSuccess, onError = defaultError) => {
  return useQuery(
    ['transaction-list', params],
    () => AdminTransactionService.getTransactionList({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

export { useListTransactions };

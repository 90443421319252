import client from 'libs/HttpClient';

class FaqCategoryService {
  static listFaqCategory({ params }) {
    return client.get(`faq_category/list`, { params });
  }
  static addFaqCategory(request) {
    return client.post(`faq_category/add`, request);
  }
  static viewFaqCategory(params) {
    return client.get(`faq_category/get`, { params });
  }
  static updateFaqCategory(request) {
    return client.put(`faq_category/edit`, request);
  }
  static deleteFaqCategory(params) {
    return client.delete(`faq_category/delete`, { params });
  }
  static statusChangeFaqCategory(request) {
    return client.put(`faq_category/updateStatus`, request);
  }
}
export { FaqCategoryService };

import { CommonHelmet, TNBreadCrumb, TNButton, TNTable } from 'common';
import { LIST } from 'config';
import { REPORT } from 'config';
import { dateFormatCommon, setFormatDateAndTimeWithSeparator } from 'helpers';
import {
  useGetReport,
  useGetReportedUserName,
  useGetReportedByUserName,
  useStateChangeOfReport,
} from 'hooks';
import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { reportTypeFilter } from 'config';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ReportList = ({ t }) => {
  const [currentPage, setCurrentPage] = useState('1');
  const [reportData, setReportData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchTextFilter, setSearchTextFilter] = useState('');

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [paginationData, setPaginationData] = useState(null);
  const fromDateFormatted = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
  const toDateFormatted = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
  const [reportType, setReportType] = useState();
  const [reportByusername, setReportByusername] = useState();
  const [reportedUsername, setReportedusername] = useState();

  const { refetch } = useGetReport(
    {
      current_page: currentPage,
      search_text_filter: searchTextFilter,
      report_type: reportType,
      report_by_user: reportByusername,
      reported_user: reportedUsername,
      from_date: fromDateFormatted,
      to_date: toDateFormatted,
    },
    (res) => {
      setReportData(res?.data?.report_list);
      setPaginationData(res.data.pagination);
    }
  );

  const { mutate: doUpdateReportStatus } = useStateChangeOfReport(
    (res) => {
      toast.success(res.message);
      refetch();
    },
    (err) => {
      toast.error(err.message);
    }
  );

  const { data: reportDataByName } = useGetReportedByUserName();
  const formattedReportData = [
    { value: '', label: t('page.reported_by_user') },
    ...(reportDataByName?.data.map((item) => ({
      value: item.reported_by,
      label: item.reported_by_username,
    })) || []),
  ];

  const { data: reportedName } = useGetReportedUserName();
  const formattedReport = [
    { value: '', label: t('page.reported_user') },
    ...(reportedName?.data.map((item) => ({
      value: item.reported_id,
      label: item.reported_username,
    })) || []),
  ];
  const reportByUserNameFilter = formattedReportData;
  const reportedFilter = formattedReport;

  const handleReportStatusClick = (report_id, type, userId, status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <div className="alert-popup">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <h2>{t('page.status_report_status_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={() => {
                  let redirection_url =
                    type == '1' ? `/user-profile-view/${userId}` : `/ads_details/31`;

                  doUpdateReportStatus({ report_id, status, redirection_url });
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      },
    });
  };

  const columnsJson = [
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.report_created_at'),
          }}></span>
      ),
      accessor: 'created_at',
      disableSortBy: false,
      Cell: ({ value }) => {
        return (
          <>
            <span>{setFormatDateAndTimeWithSeparator(value).split('|')[0]}</span>
            <br />
            <span>{setFormatDateAndTimeWithSeparator(value).split('|')[1]}</span>
          </>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.report_reported_by'),
          }}></span>
      ),
      accessor: 'reported_by',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const userId = row.original.reported_by;
        const fullName = row.original.reportedBy ? row.original.reportedBy.full_name : '-';

        const handleClick = () => {
          if (userId) {
            window.location.href = `/users/view/${userId}`;
          }
        };

        return (
          <div>
            {row.original.reportedBy !== null ? (
              <span className="reportedBy" onClick={handleClick}>
                {fullName}
              </span>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.report_reported_id'),
          }}></span>
      ),
      accessor: 'reported_id',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        let userId = '';

        if (row.original.report_type == '1') {
          userId = row.original.reported_id;
        } else if (row.original.report_type == '2') {
          userId = row?.original?.reportedAdvertisement?.user_id;
        }

        const fullName =
          row.original.reportedProfile !== null ? row?.original?.reportedProfile?.full_name : '-';
        const postName =
          row.original.reportedAdvertisement !== null
            ? row?.original?.reportedAdvertisement?.user?.full_name
            : '-';
        const chatUserName =
          row.original.reportedMessage !== null
            ? row?.original?.reportedMessage?.fromUser?.full_name
            : '-';
        const ads_status =
          row.original.reportedAdvertisement !== null &&
          row?.original?.reportedAdvertisement?.ad_status;

        const chat_reporeted_id =
          row.original.reportedMessage !== null &&
          row?.original?.reportedMessage?.fromUser?.user_id;

        const handleClick = () => {
          if (userId) {
            window.location.href = `/users/view/${userId}`;
          }
        };

        const handleChatClick = () => {
          if (chat_reporeted_id) {
            window.location.href = `/users/view/${chat_reporeted_id}`;
          }
        };

        const handleAdsClick = () => {
          if (userId) {
            if (ads_status == '2' || ads_status == '3')
              window.location.href = `/ads-master/current/view/${userId}`;
          } else if (ads_status == '4') {
            window.location.href = `/ads-master/cancelled/view/${userId}`;
          } else if (ads_status == '5') {
            window.location.href = `/ads-master/completed/view/${userId}`;
          }
        };

        return (
          <div>
            {row.original.report_type == '1' && row.original.reportedProfile !== null && (
              <span className="reportedBy" onClick={handleClick}>
                {fullName}
              </span>
            )}
            {row.original.report_type == '2' && row.original.reportedAdvertisement !== null && (
              <span className="reportedBy" onClick={handleClick}>
                {postName}
              </span>
            )}
            {row.original.report_type == '3' && row.original.reportedMessage !== null && (
              <span className="reportedBy" onClick={handleChatClick}>
                {chatUserName}
              </span>
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <span
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
          dangerouslySetInnerHTML={{
            __html: t('page.report_type'),
          }}></span>
      ),
      accessor: 'report_type',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <div>
            {row.original.report_type == '1' && <>{t('page.profile_type')}</>}
            {row.original.report_type == '2' && <>{t('page.advertisement_type')}</>}
            {row.original.report_type == '3' && <>{t('page.chat_type')}</>}
          </div>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: `${t('page.report_post_details')} |<br/>${t('page.report_chat_details')}`,
          }}></span>
      ),
      accessor: 'report_details',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        let userId = row.original.reported_id;

        const fullName =
          row.original.reportedProfile !== null ? row?.original?.reportedProfile?.full_name : '-';
        const postName =
          row.original.reportedAdvertisement !== null
            ? row?.original?.reportedAdvertisement?.post_title
            : '-';
        const chatUserName =
          row.original.reportedMessage !== null ? row?.original?.reportedMessage?.message : '-';
        const ads_status =
          row.original.reportedAdvertisement !== null &&
          row?.original?.reportedAdvertisement?.ad_status;

        const handleClick = () => {
          if (userId) {
            window.location.href = `/users/view/${userId}`;
          }
        };

        const handleAdsClick = () => {
          if (userId) {
            if (ads_status == '2' || ads_status == '3')
              window.location.href = `/ads-master/current/view/${userId}`;
          } else if (ads_status == '4') {
            window.location.href = `/ads-master/cancelled/view/${userId}`;
          } else if (ads_status == '5') {
            window.location.href = `/ads-master/completed/view/${userId}`;
          }
        };

        return (
          <div>
            {row.original.report_type == '1' && row.original.reportedProfile !== null && (
              <span className="reportedBy">{'-'}</span>
            )}
            {row.original.report_type == '2' && row.original.reportedAdvertisement !== null && (
              <span className="reportedBy" onClick={handleAdsClick}>
                {postName}
              </span>
            )}
            {row.original.report_type == '3' && row.original.reportedMessage !== null && (
              <span>{chatUserName}</span>
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.report_reason'),
          }}></span>
      ),
      accessor: 'reason',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <span
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'normal',
              // maxWidth: '300px',
            }}>
            {value}
          </span>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.report_status'),
          }}></span>
      ),
      accessor: 'status',
      Cell: ({ value: initialValue, row }) => {
        let userId = '';

        if (row.original.report_type == '1') {
          userId = row.original.reported_id;
        } else if (row.original.report_type == '2') {
          userId = row?.original?.reported_id;
        }
        return (
          <div className="action_btn">
            {row.original.status == '1' && (
              <div>
                <span
                  style={{ cursor: 'pointer', color: 'green' }}
                  id={row.original.report_id}
                  className={`mx-1  status-active`}
                  onClick={() =>
                    handleReportStatusClick(
                      row.original.report_id,
                      row.original.report_type,
                      userId,
                      2
                    )
                  }>
                  {t('page.report_show_approve_status')}
                </span>{' '}
                /
                <span
                  style={{ cursor: 'pointer', color: 'red' }}
                  id={row.original.report_id}
                  className={`mx-1  status-active`}
                  onClick={() =>
                    handleReportStatusClick(
                      row.original.report_id,
                      row.original.report_type,
                      userId,
                      3
                    )
                  }>
                  {t('page.report_show_reject_status')}
                </span>
              </div>
            )}
            {row.original.status == '2' && (
              <div style={{ color: 'green' }}>{t('page.report_approve_status')}</div>
            )}
            {row.original.status == '3' && (
              <div style={{ color: 'red' }}>{t('page.report_reject_status')}</div>
            )}
          </div>
        );
      },
      disableSortBy: true,
    },
  ];
  const columns = useMemo(() => columnsJson, []);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSearch = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length > 0) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const clearDateFilter = (date) => {
    setCurrentPage(1);
    setFromDate('');
    setToDate('');
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchTextFilter('');
    setReportType('');
    setReportByusername('');
    setReportedusername('');
  };

  const breadCrumbArray = [
    {
      label: t('page.report_list_label'),
      link: REPORT + LIST,
      active: 'active',
    },
  ];

  const options = reportTypeFilter(t);
  return (
    <>
      <TNBreadCrumb breadCrumbArray={breadCrumbArray} />
      <CommonHelmet title={'Report List'} description={'Report List'} />
      <Row>
        {/* new */}
        <Col lg={12}>
          <div className="filter">
            <Form.Group className="filter-field-space filter-status">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === reportType)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setReportType(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group className="filter-field-space filter-status-custom">
              <Select
                className="filter-column"
                options={reportByUserNameFilter}
                value={reportByUserNameFilter.filter((option) => option.value == reportByusername)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setReportByusername(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group className="filter-field-space filter-status-custom">
              <Select
                className="filter-column"
                options={reportedFilter}
                value={reportedFilter.filter((option) => option.value == reportedUsername)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setReportedusername(selectedOption.value);
                }}
              />
            </Form.Group>

            <div className="d-flex justify-content-between ">
              <Form.Group controlId="search" className="filter-field-space filter-status w-100 ">
                <Form.Control
                  placeholder="Search"
                  className="filter-column "
                  onKeyUp={handleSearch}
                  onChange={handleSearch}
                  value={searchText}
                />
              </Form.Group>
            </div>
            <div className="table-add-button filter-field-space">
              <TNButton loading={false} type="button" onClick={handleClearSearch}>
                {t('page.cancel_search_button')}
              </TNButton>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="filter">
            <Form.Label className="field-label date-picker-label">
              {t('page.report_from_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space filter-status">
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column form-field"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={fromDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setFromDate(date);
                  setCurrentPage(1);
                }}
                maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
              />
            </Form.Group>
            <Form.Label className="field-label date-picker-label">
              {t('page.report_to_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space filter-status">
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column form-field"
                placeholderText={dateFormatCommon().toUpperCase()}
                minDate={fromDate}
                selected={toDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setToDate(date);
                  setCurrentPage(1);
                }}
              />
            </Form.Group>
            <div className="table-add-button filter-field-space">
              <TNButton loading={false} type="button" onClick={clearDateFilter}>
                {t('page.report_clear_date_filter_button')}
              </TNButton>
            </div>
          </div>
        </Col>
      </Row>
      <div className="content-wrapper">
        <h1>{t('page.report_list_label')}</h1>
        <TNTable
          columns={columns}
          data={reportData}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        />
      </div>
    </>
  );
};

export default ReportList;

import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { useGetSettingData } from 'hooks';
import { addSetting, settingData } from 'store';
import { LIST, ADD, EDIT } from 'config';

const ListTeamMemberPage = React.lazy(() => import('./ListTeamMemberPage'));
const AddTeamMemberPage = React.lazy(() => import('./AddTeamMemberPage'));
const EditTeamMemberPage = React.lazy(() => import('./EditTeamMemberPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [LIST]: 'Collegely | Team Members List',
  [ADD]: 'Collegely | Add Team Member',
  [EDIT]: 'Collegely | Edit Team Member',
};

const TeamMemberRoute = ({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  // Fetch and store settings data
  useGetSettingData(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting.common_header_logo,
      front_logo: setting.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting.seo_title,
      seo_description: setting.seo_description,
      setting_get: false,
    };
    dispatch(addSetting(dataStore));
  });

  const getSettingData = useSelector(settingData);

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    if (path.includes(LIST)) return routeTitleMapping[LIST];
    if (path.includes(ADD)) return routeTitleMapping[ADD];
    if (path.includes(EDIT)) return routeTitleMapping[EDIT];
    return 'Collegely | Team'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="og:title" content={getSettingData.seo_title} />
        <meta name="title" content={getSettingData.seo_title} />
        <meta name="description" content={getSettingData.seo_description} />
        <meta name="og:description" content={getSettingData.seo_description} />
        <link rel="icon" href={getSettingData.favicon_icon} />
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListTeamMemberPage t={t} />} />
        <Route exact path={ADD} element={<AddTeamMemberPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditTeamMemberPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

TeamMemberRoute.propTypes = {
  t: PropTypes.func,
};

export default TeamMemberRoute;

import { toast } from 'react-toastify';

const { FaqService } = require('api');
const { useQuery, useMutation } = require('react-query');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListFaq = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['faq-list', params], () => FaqService.listFaq({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.addFaq, {
    onSuccess,
    onError,
  });
};

const useViewFaq = (faq_id, onSuccess, onError = onDefaultError) => {
  return useQuery('faq-view', () => FaqService.viewFaq({ faq_id }), {
    onSuccess,
    onError,
  });
};
const useUpdateFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.updateFaq, {
    onSuccess,
    onError,
  });
};

const useGetCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category', FaqService.getFaqCategory, {
    onSuccess,
    onError,
  });
};

const useGetCategoryListFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category-list', FaqService.getFaqCategoryList, {
    onSuccess,
    onError,
  });
};

const useStatusChangeFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.statusChangeFaq, {
    onSuccess,
    onError,
  });
};

const useDeleteFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqService.deleteFaq, {
    onSuccess,
    onError,
  });
};

export {
  useListFaq,
  useAddFaq,
  useViewFaq,
  useUpdateFaq,
  useGetCategoryList,
  useGetCategoryListFilter,
  useStatusChangeFaq,
  useDeleteFaq,
};

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST } from 'config';
import { VIEW } from 'config';
import { COMPLETED_ADS_MASTER } from 'config';
import { CANCELLED_ADS_MASTER } from 'config';
import { CURRENT_ADS_MASTER } from 'config';

const CompletedAdsPage = React.lazy(() => import('./CompletedAdsPage'));
const CancelledAdsPage = React.lazy(() => import('./CancelledAdsPage'));
const ViewCompletedList = React.lazy(() => import('./ViewCompletedList'));
const ViewCancelledList = React.lazy(() => import('./ViewCancelledList'));
const CurrentAdsList = React.lazy(() => import('./CurrentAds/CurrentAdsList'));
const ViewCurrentList = React.lazy(() => import('./CurrentAds/ViewCurrentList'));
const AdsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={CURRENT_ADS_MASTER + LIST} element={<CurrentAdsList t={t} />} />
      <Route
        exact
        path={CURRENT_ADS_MASTER + VIEW + ':advertisement_id'}
        element={<ViewCurrentList t={t} />}
      />
      <Route index exact path={COMPLETED_ADS_MASTER + LIST} element={<CompletedAdsPage t={t} />} />
      <Route index exact path={CANCELLED_ADS_MASTER + LIST} element={<CancelledAdsPage t={t} />} />
      <Route
        exact
        path={COMPLETED_ADS_MASTER + VIEW + ':advertisement_id'}
        element={<ViewCompletedList t={t} />}
      />
      <Route
        exact
        path={CANCELLED_ADS_MASTER + VIEW + ':advertisement_id'}
        element={<ViewCancelledList t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AdsRoutes.propTypes = {
  t: PropTypes.func,
};
export default AdsRoutes;

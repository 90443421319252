import client from 'libs/HttpClient';

class AdminHomeService {
  static homeCount(request) {
    return client.get(`home/get-home-data`, request);
  }
  static adsDetails({ params }) {
    return client.get(`home/get-ad-details`, { params });
  }
  static usersDetails({ params }) {
    return client.get(`home/get-user-details`, { params });
  }
  static paymentDetails({ params }) {
    return client.get(`home/get-revenue-details`, { params });
  }
}

export { AdminHomeService };

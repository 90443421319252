import React, { useState, useMemo } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

import 'assets/scss/page/_alert_confirm.scss';
import { dateFormatCommon, setFormatDateAndTimeWithSeparator } from 'helpers';
import { CommonHelmet, TNBreadCrumb, TNButton, TNTable } from 'common';
import { useListTransactions } from 'hooks';
import { LIST } from 'config';
import { TRANSACTION } from 'config';
import Select from 'react-select';
import { transactionOption } from 'config';
const ListTransactions = ({ t }) => {
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [transactionData, setTransactionData] = useState('');
  const [searchTextFilter, setSearchTextFilter] = useState('');
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [paginationData, setPaginationData] = useState(null);
  const navigate = useNavigate();
  const options = transactionOption(t);
  const fromDateFormatted = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
  const toDateFormatted = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
  const { refetch } = useListTransactions(
    {
      current_page: currentPage,
      search_text_filter: searchTextFilter,
      status: status,
      from_date: fromDateFormatted,
      to_date: toDateFormatted,
    },
    (res) => {
      setTransactionData(res?.data?.transaction_list);
      setPaginationData(res.data.pagination);
    }
  );

  const handleSearch = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length > 0) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const clearDateFilter = (date) => {
    setCurrentPage(1);
    setFromDate('');
    setToDate('');
  };

  const columnsJson = [
    {
      Header: (
        <span
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          }}
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_created_at'),
          }}></span>
      ),
      accessor: 'created_at',
      disableSortBy: false,
      Cell: ({ value }) => {
        return (
          <>
            <span>{setFormatDateAndTimeWithSeparator(value).split('|')[0]}</span>
            <br />
            <span>{setFormatDateAndTimeWithSeparator(value).split('|')[1]}</span>
          </>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_transaction_id_label'),
          }}></span>
      ),
      accessor: 'transaction_id',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              maxWidth: '100%',
              display: 'block',
            }}>
            {value || '-'}
          </span>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_ads_name'),
          }}></span>
      ),
      accessor: 'advertisement_name',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <>
            <span
              style={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
              }}>
              {value != '' && value != 'null' ? value : '-'}
            </span>
            <br />
          </>
        );
      },
    },
    // {
    //   Header: (
    //     <span
    //       style={{
    //         wordWrap: 'break-word',
    //         whiteSpace: 'normal',
    //       }}>
    //       {t('page.payment_history_category')}
    //     </span>
    //   ),
    //   accessor: 'subcategory_names',
    //   disableSortBy: false,
    //   Cell: ({ row }) => {
    //     return (
    //       <>
    //         <span>
    //           {row.original.parent_category_name} {row.original.parent_category_name && '| '}
    //           <br />
    //           {row.original.subcategory_names}{' '}
    //         </span>
    //         <br />
    //       </>
    //     );
    //   },
    // },
    {
      Header: (
        <span
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_validity'),
          }}></span>
      ),
      accessor: 'validity_in_days',
      disableSortBy: true,
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_price'),
          }}></span>
      ),
      accessor: 'advertisement_price',
      disableSortBy: true,
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_user_name'),
          }}></span>
      ),
      accessor: 'user_name',
      disableSortBy: true,
    },
    {
      Header: (
        <span
          style={{
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_start_end_date'),
          }}></span>
      ),
      accessor: 'start_date',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <span>
              {row.original.start_date}
              <br />
              {' to'}
              <br />
              {row.original.end_date}
            </span>
            <br />
          </>
        );
      },
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.payment_history_status'),
          }}></span>
      ),
      accessor: 'payment_status',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <>
            {value == 1 ? (
              <span
                className="me-2 transaction-table-actions text-light p-1 px-2 rounded-5 text-decoration-none status-btn"
                style={{ backgroundColor: '#0a5640' }}>
                {'Success'}
              </span>
            ) : (
              <span
                className="me-2 transaction-table-actions text-light p-1 px-3 rounded-5 text-decoration-none status-btn"
                style={{ backgroundColor: '#d42a2a' }}>
                {'Failed'}
              </span>
            )}

            <br />
          </>
        );
      },
    },
  ];
  const columns = useMemo(() => columnsJson, []);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleClearSearch = () => {
    setSearchText('');
    setSearchTextFilter('');
    setStatus('');
  };
  const breadCrumbArray = [
    {
      label: t('page.transaction_list_label'),
      link: TRANSACTION + LIST,
      active: 'active',
    },
  ];
  return (
    <>
      <TNBreadCrumb breadCrumbArray={breadCrumbArray} />
      <CommonHelmet title={'Transactions List'} description={'Transactions List'} />
      <Row>
        {/* new */}
        <Col lg={12}>
          <div className="filter">
            <Form.Group className="filter-field-space filter-status">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === status)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setStatus(selectedOption.value);
                }}
              />
            </Form.Group>

            <div className="d-flex justify-content-between ">
              <Form.Group controlId="search" className="filter-field-space filter-status w-100 ">
                <Form.Control
                  placeholder="Search"
                  className="filter-column "
                  onKeyUp={handleSearch}
                  onChange={handleSearch}
                  value={searchText}
                />
              </Form.Group>
            </div>
            <div className="table-add-button filter-field-space">
              <TNButton loading={false} type="button" onClick={handleClearSearch}>
                {t('page.cancel_search_button')}
              </TNButton>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="filter">
            <Form.Label className="field-label date-picker-label">
              {t('page.transaction_from_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space filter-status">
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column form-field"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={fromDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setFromDate(date);
                  setCurrentPage(1);
                }}
                maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
              />
            </Form.Group>
            <Form.Label className="field-label date-picker-label">
              {t('page.transaction_to_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space filter-status">
              <DatePicker
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column form-field"
                placeholderText={dateFormatCommon().toUpperCase()}
                minDate={fromDate}
                selected={toDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setToDate(date);
                  setCurrentPage(1);
                }}
              />
            </Form.Group>
            <div className="table-add-button filter-field-space">
              <TNButton loading={false} type="button" onClick={clearDateFilter}>
                {t('page.transaction_clear_date_filter_button')}
              </TNButton>
            </div>
          </div>
        </Col>
      </Row>
      <div className="content-wrapper">
        <h1>{t('page.transaction_list_label')}</h1>
        <TNTable
          columns={columns}
          data={transactionData}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        />
      </div>
    </>
  );
};

export default ListTransactions;

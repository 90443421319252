import client from 'libs/HttpClient';

class AdvertisementService {
  static getAdvertisementList({ params }) {
    return client.get(`/ads/list`, { params });
  }

  static updatePostStatus(request) {
    return client.put(`/ads/post-status/change`, request);
  }

  static viewPost(params) {
    return client.get(`/ads/view-post`, { params });
  }

  static updatePostAdminStatus(request) {
    return client.put(`/ads/post-admin-status`, request);
  }
  static getCompleted({ params }) {
    return client.get('/ads/list-completed', { params });
  }
  static getCancelled({ params }) {
    return client.get('/ads/list-cancelled', { params });
  }
}

export { AdvertisementService };

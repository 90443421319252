import client from 'libs/HttpClient';

class CMSService {
  static listCms({ params }) {
    return client.get(`cms/list`, { params });
  }
  static addCms(request) {
    return client.put('cms/add', request);
  }
  static updateStatusCms(request) {
    return client.put(`cms/update-status/`, request);
  }
  static updateCms(request) {
    return client.put(`cms/edit/`, request);
  }
  static viewCms(params) {
    return client.get(`cms/get`, { params });
  }
  static deleteCms(params) {
    return client.delete(`/cms/delete/`, { params });
  }
}
export { CMSService };

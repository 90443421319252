import client from 'libs/HttpClient';

class TeamMemberService {
  static listTeamMember({ params }) {
    return client.get(`team/list`, { params });
  }
  static addTeamMember(request) {
    return client.post(`team/store`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewTeamMember(params) {
    return client.get(`team/get`, { params });
  }
  static updateTeamMember(request) {
    return client.post(`team/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteTeamMember(params) {
    return client.delete(`team/delete`, { params });
  }
}

export { TeamMemberService };

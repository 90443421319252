import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { LIST, ADD, EDIT } from 'config';

const ListTranslationPage = React.lazy(() => import('./ListTranslationPage'));
const AddTranslationPage = React.lazy(() => import('./AddTranslationPage'));
const EditTranslationPage = React.lazy(() => import('./EditTranslationPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [LIST]: 'Collegely | Translation List',
  [ADD]: 'Collegely | Add Translation',
  [EDIT]: 'Collegely | Edit Translation',
};

const TranslationRoutes = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    // Check for exact route matches
    if (path.includes(LIST)) return routeTitleMapping[LIST];
    if (path.includes(ADD)) return routeTitleMapping[ADD];
    if (path.includes(EDIT)) return routeTitleMapping[EDIT];
    return 'Collegely | Translation'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListTranslationPage t={t} />} />
        <Route exact path={ADD} element={<AddTranslationPage t={t} />} />
        <Route exact path={EDIT + ':translation_id'} element={<EditTranslationPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

TranslationRoutes.propTypes = {
  t: PropTypes.func,
};

export default TranslationRoutes;

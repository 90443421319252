import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'assets/scss/page/_alert_confirm.scss';
import { CommonHelmet, TNBreadCrumb } from 'common';
import { useViewCategory } from 'hooks';
import { LIST, EDIT, CATEGORIES } from 'config';

const ViewCategory = ({ t }) => {
  const navigate = useNavigate();
  let { category_id } = useParams();
  const breadcrumbArray = [
    {
      label: t('page.category_list_label'),
      link: CATEGORIES + LIST,
      active: '',
    },
    {
      label: t('page.view_category_label'),
      link: CATEGORIES + EDIT + category_id,
      active: 'active',
    },
  ];

  const { data: viewCategoryData } = useViewCategory(
    Number(category_id),
    (data) => {},
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  return (
    <>
      <CommonHelmet title={'Category Details'} description={'Category Details'} />
      <TNBreadCrumb breadCrumbArray={breadcrumbArray} />
      <Card className="inner-box mt-4">
        <div className="edit-profile-form p-4 py-5 custom-view-page w-100">
          <h1 className="text-start mb-4">{t('page.view_category_label')}</h1>
          <Row>
            <Col md={12}>
              <h5 className="f-w-500 my-3">
                {t('page.category_parent_category')}:
                <span className="ms-2">{viewCategoryData?.data?.parent_category_name}</span>
              </h5>
            </Col>
            <Col md={12}>
              <h5 className="f-w-500 my-3">
                {t('page.category_name')}:
                <span className="ms-2">{viewCategoryData?.data?.category_name}</span>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h5 className="f-w-500 my-3">
                {t('page.category_type')}:
                <span className="ms-2">
                  {viewCategoryData?.data?.category_type === 1
                    ? 'Parent Category'
                    : viewCategoryData?.data?.category_type === 2
                    ? 'Sub Category'
                    : 'Individual Category'}
                </span>
              </h5>
            </Col>
            <Col md={12}>
              <h5 className="f-w-500 my-3">
                {t('page.category_keyword')}:
                <span className="ms-2">{viewCategoryData?.data?.keyword}</span>
              </h5>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <h5 className="f-w-500 my-3">
                {t('page.category_sequence')}:
                <span className="ms-2">{viewCategoryData?.data?.sequence}</span>
              </h5>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

ViewCategory.propTypes = {
  t: PropTypes.func.isRequired,
  isDirtyForm: PropTypes.any,
};

export default ViewCategory;

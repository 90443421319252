import { AdminCategoryService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListCategory = (params, onSuccess, onError) => {
  return useQuery(
    ['category_list', [params]],
    () => {
      return AdminCategoryService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminCategoryService.add, {
    onSuccess,
    onError,
  });
};
const useGetParentCategory = (onSuccess, onError) => {
  return useQuery(
    ['parent_category_list'],
    () => {
      return AdminCategoryService.getParentCategory();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useStatusChange = (onSuccess, onError) => {
  return useMutation(AdminCategoryService.changeStatus, {
    onSuccess,
    onError,
  });
};
const useViewCategory = (params, onSuccess, onError) => {
  return useQuery(
    ['category_view', [params]],
    () => {
      return AdminCategoryService.view({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useEditCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminCategoryService.edit, {
    onSuccess,
    onError,
  });
};
const useDeleteCategory = (onSuccess, onError) => {
  return useMutation(AdminCategoryService.delete, {
    onSuccess,
    onError,
  });
};

const useGetSubCategory = (onSuccess, onError) => {
  return useMutation(AdminCategoryService.getSubCategory, {
    onSuccess,
    onError,
  });
};
const useGetAllSubCategory = (onSuccess, onError) => {
  return useQuery(
    ['all_sub_category_list'],
    () => {
      return AdminCategoryService.getAllSubCategory();
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListCategory,
  useAddCategory,
  useViewCategory,
  useEditCategory,
  useGetParentCategory,
  useStatusChange,
  useDeleteCategory,
  useGetSubCategory,
  useGetAllSubCategory,
};

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT, VIEW } from 'config';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import SelectCategory from './SelectCategory';
import ViewCategory from './ViewCategory';

const CategoryList = React.lazy(() => import('./CategoryListPage'));

const CategoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<CategoryList t={t} />} />
      <Route exact path={ADD} element={<AddCategory t={t} />} />
      <Route exact path={ADD + '/:category_type'} element={<SelectCategory t={t} />} />
      <Route exact path={VIEW + ':category_id'} element={<ViewCategory t={t} />} />
      <Route exact path={EDIT + ':category_id'} element={<EditCategory t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CategoryRoutes.propTypes = {
  t: PropTypes.func,
};
export default CategoryRoutes;

import client from 'libs/HttpClient';

class AdminAuthService {
  static login(request) {
    return client.post(`auth/login`, request);
  }
  static forgotPassword(request) {
    return client.put(`auth/forgot-password`, request);
  }
  static resendOtp(request) {
    return client.put(`auth/forgot-password/resend-otp`, request);
  }
  static otpVerify(request) {
    return client.put(`auth/verify-forgot-otp`, request);
  }
  static resetPassword(request) {
    return client.patch(`auth/reset-password`, request);
  }
  static generateRefreshToken(request) {
    return client.get(`auth/generate-refresh-token`, request);
  }
}

export { AdminAuthService };

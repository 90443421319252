import { AdvertisementService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetAdsList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['advertisement_list', [params]],
    () => {
      return AdvertisementService.getAdvertisementList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const usePostStatusChangeUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdvertisementService.updatePostStatus, {
    onSuccess,
    onError,
  });
};

const usePostAdminStatusChange = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdvertisementService.updatePostAdminStatus, {
    onSuccess,
    onError,
  });
};

const useViewPost = (advertisement_id, onSuccess, onError = onDefaultError) => {
  return useQuery('user-view', () => AdvertisementService.viewPost({ advertisement_id }), {
    onSuccess,
    onError,
  });
};
const useGetCompletedList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-completed-list', [params]],
    () => {
      return AdvertisementService.getCompleted({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetCancelledList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-cancelled-list', [params]],
    () => {
      return AdvertisementService.getCancelled({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetAdsList,
  usePostStatusChangeUser,
  useViewPost,
  usePostAdminStatusChange,
  useGetCompletedList,
  useGetCancelledList,
};

import client from 'libs/HttpClient';

class AdminBusinessTypesService {
  static getList({ params }) {
    return client.get(`business-type/list`, { params });
  }
  static add(request) {
    return client.post(`business-type/add`, request);
  }
  static get(params) {
    return client.get(`business-type/view`, { params });
  }
  static edit(request) {
    return client.put(`business-type/edit`, request);
  }
  static delete(params) {
    return client.delete(`business-type/delete`, { params });
  }
  static changeStatus(request) {
    return client.put(`business-type/status-change`, request);
  }
}

export { AdminBusinessTypesService };

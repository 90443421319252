import { ChatService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListChatRooms = (onSuccess, onError) => {
  return useQuery(
    ['chat_room_list'],
    () => {
      return ChatService.listChatRooms();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useListChatUsers = (onSuccess, onError) => {
  return useQuery(
    ['users_list'],
    () => {
      return ChatService.listUsers();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useListChatMessages = (params, onSuccess, onError) => {
  return useQuery(
    ['chat_messages_list', [params]],
    () => {
      return ChatService.listChatMessages({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useAddChatRoom = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.createChatRoom, {
    onSuccess,
    onError,
  });
};
const useAddChatMessage = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.sendMessage, {
    onSuccess,
    onError,
  });
};
const useClearChat = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.clearChat, {
    onSuccess,
    onError,
  });
};
const useDeleteChat = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.deleteChat, {
    onSuccess,
    onError,
  });
};
const useMarkRead = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.markRead, {
    onSuccess,
    onError,
  });
};
const useDeleteMsg = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.deleteMsg, {
    onSuccess,
    onError,
  });
};
const useReportMsg = (onSuccess, onError = onDefaultError) => {
  return useMutation(ChatService.reportMsg, {
    onSuccess,
    onError,
  });
};
const useListUnreadMessage = (onSuccess, onError) => {
  return useQuery(
    ['chat_messages_list'],
    () => {
      return ChatService.unreadMsgs();
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useListChatRooms,
  useAddChatRoom,
  useListChatMessages,
  useAddChatMessage,
  useClearChat,
  useDeleteChat,
  useMarkRead,
  useDeleteMsg,
  useReportMsg,
  useListUnreadMessage,
  useListChatUsers,
};

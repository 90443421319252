import { ReportService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetReport = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['report_list', [params]],
    () => {
      return ReportService.getReportData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetReportedUserName = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'reported_user',
    () => {
      return ReportService.getReportedUser();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetReportedByUserName = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'report_by_user',
    () => {
      return ReportService.getReportedByUser();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useStateChangeOfReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReportService.useStatusReportChange, {
    onSuccess,
    onError,
  });
};

export { useGetReport, useGetReportedByUserName, useGetReportedUserName, useStateChangeOfReport };

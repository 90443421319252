import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  useGetHomeAdsDetails,
  useGetHomeData,
  useGetHomePaymentDetails,
  useGetHomeUserDetails,
} from 'hooks';
import { Col, Row } from 'react-bootstrap';
import 'assets/scss/page/_dashboard.scss';
import completedAdsIcon from 'assets/images/dashboard_completed_ads_icon.svg';
import currentAdsIcon from 'assets/images/dashboard_current_ads_icon.svg';
import cancelledAdsIcon from 'assets/images/dashboard_cancelled_ads_icon.svg';
import totalRevenueIcon from 'assets/images/dashboard_total_revenue_icon.svg';
import usersIcon from 'assets/images/dashboard_user_icon.svg';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CommonHelmet } from 'common';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.375rem',
    color: 'white',
    width: '100%',
    fontWeight: 400,
    fontSize: '15px',
    minWidth: '130px',
    background: 'transparent',
    minHeight: 45,
    paddingLeft: '0.5rem',
    boxShadow: 'none',
    border: '1px solid #ced4da',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (styles) => ({
    ...styles,
    maxWidth: '100%',
    minWidth: '100px',
  }),
};

const DashboardPage = ({ t }) => {
  const navigate = useNavigate();
  const [revenueData, setRevenueData] = useState({});
  const [userData, setUserData] = useState({});
  const [adsData, setAdsData] = useState({});
  const [dashboardData, setDashboardData] = useState(null);
  const [revenueYear, setRevenueYear] = useState(new Date().getFullYear());
  const [userYear, setUserYear] = useState(new Date().getFullYear());
  const [adsYear, setAdsYear] = useState(new Date().getFullYear());

  const { refetch } = useGetHomeData(
    (res) => {
      setDashboardData(res?.data);
    },
    (error) => {
      console.error('error', error);
    }
  );

  const { refetch: getAdsData } = useGetHomeAdsDetails(
    { year: adsYear },
    (res) => {
      if (res?.data) {
        const data = {
          labels: res?.data?.monthlyAds?.map((payment) => payment?.month),
          datasets: [
            {
              label: 'Created Ads',
              data: res?.data?.monthlyAds?.map((payment) => payment?.totalAds),
              borderColor: 'white',
              backgroundColor: 'white',
              fill: true,
              tension: 0.4,
            },
          ],
        };
        setAdsData(data);
      }
    },
    (error) => {
      console.error('error', error);
    }
  );

  const { refetch: getUserData } = useGetHomeUserDetails(
    { year: userYear },
    (res) => {
      if (res?.data) {
        const data = {
          labels: res?.data?.userData?.map((payment) => payment?.month),
          datasets: [
            {
              label: 'Normal Users',
              data: res?.data?.userData?.map((payment) => payment.normalUsers),
              borderColor: 'white',
              backgroundColor: 'white',
              fill: true,
              tension: 0.4,
            },
            {
              label: 'Business Users',
              data: res?.data?.userData?.map((payment) => payment.businessUsers),
              borderColor: '#b3e6cc',
              backgroundColor: '#b3e6cc',
              fill: true,
              tension: 0.4,
            },
          ],
        };
        setUserData(data);
      }
    },
    (error) => {
      console.error('error', error);
    }
  );

  const { refetch: getPaymentData } = useGetHomePaymentDetails(
    { year: revenueYear },
    (res) => {
      if (res?.data) {
        const data = {
          labels: res?.data?.monthlyRevenue?.map((payment) => payment?.month),
          datasets: [
            {
              label: 'Revenue',
              data: res?.data?.monthlyRevenue?.map((payment) => payment.totalRevenue),
              borderColor: 'white',
              backgroundColor: 'white',
              fill: true,
              tension: 0.4,
            },
          ],
        };
        setRevenueData(data);
      }
    },
    (error) => {
      console.error('error', error);
    }
  );

  if (!revenueData.labels) {
    return <div>Loading...</div>;
  }

  const iconMapping = {
    dashboard_completed_ads_icon: completedAdsIcon,
    dashboard_current_ads_icon: currentAdsIcon,
    dashboard_cancelled_ads_icon: cancelledAdsIcon,
    dashboard_total_revenue_icon: totalRevenueIcon,
    dashboard_users_icon: usersIcon,
    dashboard_business_users_icon: usersIcon,
    dashboard_normal_users_icon: usersIcon,
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const currentYear = new Date().getFullYear();
  const customOptions = {
    options: Array.from(
      { length: currentYear - (Number(dashboardData?.createdYear) - 1) },
      (_, index) => {
        const year = currentYear - index;
        return { value: String(year), label: String(year) };
      }
    ),
  };

  const revenueOptions = customOptions?.options;
  const usersOptions = customOptions?.options;
  const adsOptions = customOptions?.options;

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
      point: {
        radius: 5,
      },
    },
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  };

  return (
    <>
      <CommonHelmet title={'Dashboard'} description={'Dashboard'} />
      <h5 className="dashboard-title ms-5 mt-4">{t('page.dashboard_header')}</h5>
      <Row className="row-gap-5 p-5">
        {dashboardData &&
          dashboardData?.dashboardData?.map((item, index) => {
            const Icon = iconMapping[item.icon];
            return (
              <Col lg={4} md={6} key={index}>
                <div className="data-card p-4" onClick={() => handleNavigation(item.navigate)}>
                  <div className="icon-box" style={{ backgroundColor: 'rgb(117, 50, 198)' }}>
                    <img src={Icon} className="card-img-top" alt="icon" />
                  </div>
                  <h5 className="data-title">{item.title}</h5>
                  <h2 className="data-value mt-2">{item.count}</h2>
                </div>
              </Col>
            );
          })}
      </Row>
      <Row className="mt-4 px-md-4 justify-content-between">
        <div className="chart-wrap mt-3 p-3 p-md-5 pt-4">
          <span className="d-flex justify-content-between align-items-center">
            <h2>{t('page.total_users_char_title')}</h2>
            <Select
              styles={customStyles}
              options={usersOptions}
              value={usersOptions.find((option) => option.value == userYear)}
              onChange={(selectedOption) => {
                setUserYear(selectedOption.value);
                getUserData();
              }}
            />
          </span>
          <div className="bar-div">
            {userData.labels && <Bar data={userData} options={chartOptions} />}
          </div>
        </div>
      </Row>
      <Row className="mt-4 px-md-4 justify-content-between">
        <div className="chart-wrap mt-3 p-3 p-md-5 pt-4">
          <span className="d-flex justify-content-between align-items-center">
            <h2>{t('page.total_ads_char_title')}</h2>
            <Select
              styles={customStyles}
              options={adsOptions}
              value={adsOptions.find((option) => option.value == adsYear)}
              onChange={(selectedOption) => {
                setAdsYear(selectedOption.value);
                getAdsData();
              }}
            />
          </span>
          <div className="bar-div">
            {adsData.labels && <Bar data={adsData} options={chartOptions} />}
          </div>
        </div>
      </Row>
      <Row className="mt-4 px-md-4 justify-content-between">
        <div className="chart-wrap mt-3 p-3 p-md-5 pt-4">
          <span className="d-flex justify-content-between align-items-center">
            <h2>{t('page.total_revenue_char_title')}</h2>
            <Select
              styles={customStyles}
              options={revenueOptions}
              value={revenueOptions.find((option) => option.value == revenueYear)}
              onChange={(selectedOption) => {
                setRevenueYear(selectedOption.value);
                getPaymentData();
              }}
            />
          </span>
          <div className="bar-div">
            {revenueData.labels && <Bar data={revenueData} options={chartOptions} />}
          </div>
        </div>
      </Row>
    </>
  );
};

export { DashboardPage };

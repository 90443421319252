import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LIST } from 'config';
import { CURRENT_ADS_MASTER } from 'config';
import { COMPLETED_ADS_MASTER } from 'config';
import { ADS_MASTER } from 'config';
import { CANCELLED_ADS_MASTER } from 'config';

const SidebarAdsMasterLinks = (props) => {
  const { t } = props;
  const currentClass = location.pathname.match(/\/current/) ? 'active' : '';
  const completeClass = location.pathname.match(/\/completed/) ? 'active' : '';
  const cancelClass = location.pathname.match(/\/cancelled/) ? 'active' : '';

  return (
    <>
      <ul className={`submenu collapse ${props.adsMaster ? 'show' : ''}`}>
        <li>
          <Link
            className={currentClass}
            to={ADS_MASTER + CURRENT_ADS_MASTER + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_current_master')}
          </Link>
        </li>
        <li>
          <Link
            className={completeClass}
            to={ADS_MASTER + COMPLETED_ADS_MASTER + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_completed_master')}
          </Link>
        </li>
        <li>
          <Link
            className={cancelClass}
            to={ADS_MASTER + CANCELLED_ADS_MASTER + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_cancelled_master')}
          </Link>
        </li>
      </ul>
    </>
  );
};

export { SidebarAdsMasterLinks };

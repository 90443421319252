import client from 'libs/HttpClient';

class AdminAttributesService {
  static attributeList() {
    return client.get(`attribute/attribute-list`);
  }
  static getList({ params }) {
    return client.get(`attribute/list`, { params });
  }
  static add(request) {
    return client.post(`attribute/add`, request);
  }
  static get(params) {
    return client.get(`attribute/view`, { params });
  }
  static edit(request) {
    return client.put(`attribute/edit`, request);
  }
  static delete(params) {
    return client.delete(`attribute/delete`, { params });
  }
  static changeStatus(request) {
    return client.put(`attribute/status`, request);
  }
  static getCategories(params) {
    return client.get(`attribute/get-categories`, { params });
  }
}

export { AdminAttributesService };

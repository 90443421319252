import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Lazy load components
const HomeSeoSetting = React.lazy(() => import('./HomeSeoSettingPage'));
const HomeFooterSetting = React.lazy(() => import('./HomeFooterSettingPage'));
// const HomeBannerSetting = React.lazy(() => import('./HomeBannerSettingPage'));
// const HomeJobPostSetting = React.lazy(() => import('./HomeJobPostSettingPage'));
// const HomeDownloadSetting = React.lazy(() => import('./HomeDownloadSettingPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  '/home-seo': 'Collegely | Home Seo Settings',
  '/home-footer': 'Collegely | Footer Section',
  // Add additional mappings here if needed
};

const HomeRoutes = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    return routeTitleMapping[path] || 'Collegely | Home Settings'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        {/* <Route path="/home-banner" element={<HomeBannerSetting t={t} />} /> */}
        <Route path="/home-seo" element={<HomeSeoSetting t={t} />} />
        {/* <Route path="/home-job-post" element={<HomeJobPostSetting t={t} />} /> */}
        <Route path="/home-footer" element={<HomeFooterSetting t={t} />} />
        {/* <Route path="/home-download" element={<HomeDownloadSetting t={t} />} /> */}
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

export default HomeRoutes;

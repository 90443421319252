import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { LIST, ADD, EDIT } from 'config';

const ListEmailTemplatePage = React.lazy(() => import('./ListEmailTemplatePage'));
const AddEmailTemplatePage = React.lazy(() => import('./AddEmailTemplatePage'));
const EditEmailTemplatePage = React.lazy(() => import('./EditEmailTemplatePage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [LIST]: 'Collegely | Email Templates List',
  [ADD]: 'Collegely | Add Email Template',
  [EDIT]: 'Collegely | Edit Email Template',
};

const EmailTemplateRoutes = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    if (path.includes(LIST)) return routeTitleMapping[LIST];
    if (path.includes(ADD)) return routeTitleMapping[ADD];
    if (path.includes(EDIT)) return routeTitleMapping[EDIT];
    return 'Collegely | Email Templates'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListEmailTemplatePage t={t} />} />
        <Route exact path={ADD} element={<AddEmailTemplatePage t={t} />} />
        <Route exact path={EDIT + ':email_template_id'} element={<EditEmailTemplatePage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

EmailTemplateRoutes.propTypes = {
  t: PropTypes.func,
};

export default EmailTemplateRoutes;

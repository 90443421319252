import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT, VIEW } from 'config';

const BusinessTypeList = React.lazy(() => import('./ListBusinessType'));
const AddBusinessType = React.lazy(() => import('./AddBusinessType'));
const ViewBusinessType = React.lazy(() => import('./ViewBusinessType'));
const EditBusinessType = React.lazy(() => import('./EditBusinessType'));

const BusinessTypeRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<BusinessTypeList t={t} />} />
      <Route exact path={ADD} element={<AddBusinessType t={t} />} />
      <Route exact path={VIEW + ':business_type_id'} element={<ViewBusinessType t={t} />} />
      <Route exact path={EDIT + ':business_type_id'} element={<EditBusinessType t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
BusinessTypeRoute.propTypes = {
  t: PropTypes.func,
};
export default BusinessTypeRoute;

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const { AdminStateCityService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListStateCity = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['state-city-list', params],
    () => AdminStateCityService.listStateCity({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddStateCity = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminStateCityService.addStateCity, {
    onSuccess,
    onError,
  });
};

const useViewStateCity = (state_id, onSuccess, onError = onDefaultError) => {
  return useQuery('state-city-view', () => AdminStateCityService.viewStateCity({ state_id }), {
    onSuccess,
    onError,
  });
};

const useUpdateStateCity = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminStateCityService.updateStateCity, {
    onSuccess,
    onError,
  });
};

const useStatusChangeStateCity = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminStateCityService.statusChangeStateCity, {
    onSuccess,
    onError,
  });
};

const useDeleteStateCity = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminStateCityService.deleteStateCity, {
    onSuccess,
    onError,
  });
};

export {
  useListStateCity,
  useAddStateCity,
  useViewStateCity,
  useUpdateStateCity,
  useStatusChangeStateCity,
  useDeleteStateCity,
};

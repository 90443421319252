import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { loggedUser, loader } from 'store';
import { Loader, PublicLayout } from 'common';
import { DASHBOARD } from 'config';

const PublicRoute = () => {
  // Calling selector hook from redux, to get data from stores, like loader, login info
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);

  /**
   * Checking if user is registered user or not
   */
  const isAdminLoggedIn = checkLoggedInUser.isAdminLoggedIn;

  return !isAdminLoggedIn ? (
    <PublicLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PublicLayout>
  ) : (
    <Navigate
      to={{
        pathname: DASHBOARD,
      }}
      replace
    />
  );
};

export default PublicRoute;

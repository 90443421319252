import React from 'react';
import 'assets/scss/loader/spinner.scss';
const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner"></div>
      </div>
    </div>
  );
};
export { Loader };

import client from 'libs/HttpClient';

class EmailTemplateService {
  static listEmailTemplate(params) {
    return client.get(`email-template/list`, { params });
  }
  static listEmailTemplateWithoutPagination(request) {
    return client.get(`email-template/list-without_pagination`, request);
  }
  static addEmailTemplate(request) {
    return client.put(`email-template/add`, request);
  }
  static viewEmailTemplate(params) {
    return client.get(`email-template/get`, {
      params,
    });
  }
  static updateEmailTemplate(request) {
    return client.put(`email-template/edit`, request);
  }
}
export { EmailTemplateService };

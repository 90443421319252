import client from 'libs/HttpClient';

class ReportService {
  static getReportData({ params }) {
    return client.get('report/get-report', { params });
  }
  static getReportedUser() {
    return client.get('report/get-reported-user');
  }
  static getReportedByUser() {
    return client.get('report/get-reported-by-user');
  }
  static useStatusReportChange(request) {
    return client.put(`report/report_status_change`, request);
  }
}

export { ReportService };

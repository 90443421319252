import client from 'libs/HttpClient';

class FeatureService {
  //Feature Master
  static listFeature({ params }) {
    return client.get(`feature/list`, { params });
  }
  static addFeature(request) {
    return client.post(`feature/store`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewFeature(params) {
    return client.get(`feature/get`, { params });
  }
  static updateFeature(request) {
    return client.post(`feature/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteFeature(params) {
    return client.delete(`feature/delete`, { params });
  }
  static statusChangeFeature(request) {
    return client.patch(`feature/update-status`, request);
  }
}

export { FeatureService };

// import libs
import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { io } from 'socket.io-client';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  ChangePasswordPage,
  CmsRoutes,
  DashboardPage,
  EditProfilePage,
  EmailTemplateRoutes,
  FaqCategoryRoute,
  FaqRoute,
  FeatureRoute,
  ForgetPasswordPage,
  LoginPage,
  Notifications,
  OtpVerificationPage,
  ResetPasswordPage,
  SettingsRoutes,
  TeamMemberRoute,
  UserRoutes,
  TranslationRoutes,
} from 'pages';
import {
  DASHBOARD,
  FORGOT_PASSWORD,
  LOGIN,
  OTP_VERIFY,
  RESET_PASSWORD,
  SETTING,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  EMAIL_TEMPLATE,
  CMS,
  TRANSLATIONS,
  FAQ,
  FAQ_CATEGORY,
  USERS,
  NOTIFICATIONS,
  FEATURE,
  TEAM_MEMBER,
} from 'config';
import { useGetSettingData } from 'hooks';
import { addSetting } from 'store';
import { PageNotFound } from 'common';
import { CATEGORIES } from 'config';
import { ATTRIBUTES } from 'config';
import CategoryRoutes from 'pages/Category/CategoryRoutes';
import AttributesRoute from 'pages/Attributes/AttributesRoute';

import {
  ADS_MASTER,
  BOOSTS,
  BUSINESS_TYPE,
  CHAT,
  PRICING,
  REPORT,
  STATE_CITY,
  TRANSACTION,
} from 'config/routes';
import BusinessTypeRoute from 'pages/BusinessTypes/BusinessTypeRoute';
import StateCityRotes from 'pages/Masters/StatesAndCities/StateCityRotes';
import BoostsRoute from 'pages/BoostPosts/BoostsRoute';
import PricingRoute from 'pages/Pricing/PricingRoute';
import ListTransactions from 'pages/Transactions/ListTransactions';
import AdsRoutes from 'pages/Ads/AdsRoutes';
import UserChat from 'pages/Chats/UserChat';
import ReportList from 'pages/Report/ReportList';
const PagesRoutes = ({ t }) => {
  const socketConnection = io(process.env.REACT_APP_SOCKET_URL);
  const dispatch = useDispatch();
  /**
   * This function will cal on page load, and will set data to redux store
   */
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });

  return (
    <>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path={LOGIN} element={<LoginPage t={t} />} />
          <Route exact path={FORGOT_PASSWORD} element={<ForgetPasswordPage t={t} />} />
          <Route exact path={OTP_VERIFY} element={<OtpVerificationPage t={t} />} />
          <Route exact path={RESET_PASSWORD} element={<ResetPasswordPage t={t} />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path={DASHBOARD} element={<DashboardPage t={t} />} />
          <Route exact path={EDIT_PROFILE} element={<EditProfilePage t={t} />} />
          <Route exact path={CHANGE_PASSWORD} element={<ChangePasswordPage t={t} />} />
          <Route path={EMAIL_TEMPLATE + '/*'} element={<EmailTemplateRoutes t={t} />} />
          <Route path={CMS + '/*'} element={<CmsRoutes t={t} />} />
          <Route path={TRANSLATIONS + '/*'} element={<TranslationRoutes t={t} />} />
          <Route exact path={FAQ + '/*'} element={<FaqRoute t={t} />} />
          <Route exact path={FAQ_CATEGORY + '/*'} element={<FaqCategoryRoute t={t} />} />
          <Route exact path={USERS + '/*'} element={<UserRoutes t={t} />} />
          <Route exact path={NOTIFICATIONS} element={<Notifications t={t} />} />
          <Route exact path={FEATURE + '/*'} element={<FeatureRoute t={t} />} />
          <Route exact path={TEAM_MEMBER + '/*'} element={<TeamMemberRoute t={t} />} />
          <Route path={SETTING + '/*'} element={<SettingsRoutes t={t} />} />
          <Route exact path={CATEGORIES + '/*'} element={<CategoryRoutes t={t} />} />
          <Route exact path={ATTRIBUTES + '/*'} element={<AttributesRoute t={t} />} />
          <Route exact path={ATTRIBUTES + '/*'} element={<AttributesRoute t={t} />} />
          <Route exact path={BOOSTS + '/*'} element={<BoostsRoute t={t} />} />
          <Route exact path={BUSINESS_TYPE + '/*'} element={<BusinessTypeRoute t={t} />} />
          <Route exact path={STATE_CITY + '/*'} element={<StateCityRotes t={t} />} />
          <Route exact path={PRICING + '/*'} element={<PricingRoute t={t} />} />
          <Route exact path={REPORT + '/*'} element={<ReportList t={t} />} />
          <Route exact path={TRANSACTION + '/*'} element={<ListTransactions t={t} />} />
          <Route exact path={ADS_MASTER + '/*'} element={<AdsRoutes t={t} />} />
          <Route exact path={CHAT} element={<UserChat t={t} socket={socketConnection} />} />
        </Route>
        <Route>
          {' '}
          <Route path="*" element={<PageNotFound t={t} />} from="admin" />
        </Route>
      </Routes>
    </>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export default PagesRoutes;

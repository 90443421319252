import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial Values of globally declared states in redux
 */
const initialState = {
  setting_get: true,
  home_page_general_header_logo: '',
  home_page_general_login_logo: '',
  home_page_general_email_logo: '',
  home_page_general_favicon_logo: '',
  home_page_general_seo_title: '',
  home_page_general_seo_description: '',
  home_page_general_email_address: '',
  active_user_email: '1',
  inactive_user_email: '1',
  home_page_general_post_approval_status: '2',
};

/**
 * Slice for Settings Data
 */
const settingsSlice = createSlice({
  name: 'setting',
  initialState,

  /**
   * Reducer functions for settings info
   */
  reducers: {
    addSetting: (state, action) => {
      const {
        home_page_general_header_logo,
        home_page_general_login_logo,
        home_page_general_email_logo,
        home_page_general_favicon_logo,
        home_page_general_seo_title,
        home_page_general_seo_description,
        home_page_general_email_address,
        setting_get,
        home_page_general_post_approval_status,
      } = action.payload;

      // Update state with payload values
      state.home_page_general_header_logo = home_page_general_header_logo;
      state.home_page_general_login_logo = home_page_general_login_logo;
      state.home_page_general_email_logo = home_page_general_email_logo;
      state.home_page_general_favicon_logo = home_page_general_favicon_logo;
      state.home_page_general_seo_title = home_page_general_seo_title;
      state.home_page_general_seo_description = home_page_general_seo_description;
      state.home_page_general_email_address = home_page_general_email_address;
      state.setting_get = setting_get !== undefined ? setting_get : state.setting_get; // Only update if value is provided
      state.home_page_general_post_approval_status = home_page_general_post_approval_status;
    },

    /**
     * Clear settings and reset to initial state
     */
    clearSetting: () => initialState,

    setSettings: (state, action) => {
      Object.assign(state, action.payload);
    },

    /**
     * Update email notification settings based on checkbox states
     * @param {Object} state - The current state
     * @param {Object} action - The action object containing the payload
     */
    updateSettings: (state, action) => {
      const { active_user_email, inactive_user_email } = action.payload;
      state.active_user_email = active_user_email ? '2' : '1'; // Convert checkbox state to '1' or '2'
      state.inactive_user_email = inactive_user_email ? '2' : '1'; // Convert checkbox state to '1' or '2'
    },
  },
});

// Export actions
const { addSetting, clearSetting, setSettings, updateSettings } = settingsSlice.actions;
const settingData = (state) => state.setting;

export { settingsSlice, addSetting, clearSetting, setSettings, updateSettings, settingData };
// Export reducer
export default settingsSlice.reducer;

import client from 'libs/HttpClient';

class FaqService {
  static listFaq({ params }) {
    return client.get(`faq/list`, { params });
  }
  static addFaq(request) {
    return client.put(`faq/add`, request);
  }
  static viewFaq(params) {
    return client.get(`faq/get`, {
      params,
    });
  }
  static statusChangeFaq(request) {
    return client.put(`faq/status_change`, request);
  }
  static updateFaq(request) {
    return client.put(`faq/edit`, request);
  }
  static deleteFaq(params) {
    return client.delete(`faq/delete`, { params });
  }
  static getFaqCategory() {
    return client.get(`faq/get_category`);
  }
  static getFaqCategoryList() {
    return client.get(`faq/get_category_list`);
  }
}
export { FaqService };

import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { LIST, ADD, EDIT } from 'config';

const ListFaqCategoryPage = React.lazy(() => import('./ListFaqCategoryPage'));
const AddFaqCategoryPage = React.lazy(() => import('./AddFaqCategoryPage'));
const EditFaqCategoryPage = React.lazy(() => import('./EditFaqCategoryPage'));

// Define a mapping for route titles
const routeTitleMapping = {
  [LIST]: 'Collegely | Faq Categories List',
  [ADD]: 'Collegely | Add Faq Category',
  [EDIT]: 'Collegely | Edit Faq Category',
};

const FaqCategoryRoute = ({ t }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Function to get the title based on the pathname
  const getTitleForPath = (path) => {
    if (path.includes(LIST)) return routeTitleMapping[LIST];
    if (path.includes(ADD)) return routeTitleMapping[ADD];
    if (path.includes(EDIT)) return routeTitleMapping[EDIT];
    return 'Collegely | FAQ Categories'; // Default title
  };

  // Get the title based on the current path
  const pageTitle = getTitleForPath(currentPath);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListFaqCategoryPage t={t} />} />
        <Route exact path={ADD} element={<AddFaqCategoryPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditFaqCategoryPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

FaqCategoryRoute.propTypes = {
  t: PropTypes.func,
};

export default FaqCategoryRoute;

import { AdminBusinessTypesService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetBusinessType = (params, onSuccess, onError) => {
  return useQuery(
    ['business_type_list', [params]],
    () => {
      return AdminBusinessTypesService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddBusinessType = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBusinessTypesService.add, {
    onSuccess,
    onError,
  });
};
const useDeleteBusinessType = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBusinessTypesService.delete, {
    onSuccess,
    onError,
  });
};
const useChangeBusinessTypeStatus = (onSuccess, onError) => {
  return useMutation(AdminBusinessTypesService.changeStatus, {
    onSuccess,
    onError,
  });
};
const useEditBusinessType = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBusinessTypesService.edit, {
    onSuccess,
    onError,
  });
};
const useViewBusinessType = (params, onSuccess, onError) => {
  return useQuery(
    ['admin_business_type_detail', [params]],
    () => {
      return AdminBusinessTypesService.get({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetBusinessType,
  useAddBusinessType,
  useDeleteBusinessType,
  useChangeBusinessTypeStatus,
  useEditBusinessType,
  useViewBusinessType,
};

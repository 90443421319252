import * as Yup from 'yup';
const validationSchema = Yup.object({
  category_name: Yup.string()
    .required('page.category_name_required')
    .min(2, 'page.category_name_min')
    .max(100, 'page.category_name_max'),

  keyword: Yup.string()
    .required('page.category_keyword_required')
    .min(2, 'page.category_keyword_min')
    .max(100, 'page.category_keyword_max'),

  sequence: Yup.number()
    .typeError('page.add_category_specify_number_required')
    .min(1, 'page.add_category_sequence_gt_required')
    .required('page.add_category_sequence_required')
    .test(
      'len',
      'page.add_category_specify_number_validation_max',
      (val) => val && val.toString().length <= 10
    ),

  status: Yup.string().required('page.category_status_required'),
  parent_id: Yup.string().optional(),
});
export default validationSchema;

import client from 'libs/HttpClient';

class AdminBoostsService {
  static getList({ params }) {
    return client.get(`boost-post/list`, { params });
  }
  static add(request) {
    return client.post(`boost-post/add`, request);
  }
  static get(params) {
    return client.get(`boost-post/view`, { params });
  }
  static edit(request) {
    return client.put(`boost-post/edit`, request);
  }
  static delete(params) {
    return client.delete(`boost-post/delete`, { params });
  }
  static changeStatus(request) {
    return client.put(`boost-post/status-change`, request);
  }
}

export { AdminBoostsService };

import * as Yup from 'yup';

let validationSchema = Yup.object({
  general_post_page_general_seo_title: Yup.string(
    'page.general_setting_general_post_page_general_seo_title_required'
  ).required('page.general_setting_general_post_page_general_seo_title_required'),
  general_post_page_general_seo_description: Yup.string(
    'page.general_setting_general_post_page_general_seo_description_required'
  ).required('page.general_setting_general_post_page_general_seo_description_required'),
  general_post_details_general_seo_title: Yup.string(
    'page.general_setting_general_post_details_general_seo_title_required'
  ).required('page.general_setting_general_post_details_general_seo_title_required'),
  general_post_details_general_seo_description: Yup.string(
    'page.general_setting_general_post_details_general_seo_description_required'
  ).required('page.general_setting_general_post_details_general_seo_description_required'),
  general_post_page_general_favicon_logo: Yup.mixed(
    'page.general_post_page_general_favicon_logo_required'
  ).required('page.general_post_page_general_favicon_logo_required'),
});
export default validationSchema;

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Form as BootstrapForm, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CATEGORIES, ADD } from 'config';
import { CommonHelmet } from 'common';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const initialValues = {
  category_type: '',
};

const AddCategory = () => {
  const navigate = useNavigate();

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.category_type == '') {
      toast.error('Please select category type');
      return;
    }
    localStorage.setItem('selectedCategory', values.category_type);
    navigate(CATEGORIES + ADD + '/' + values.category_type);
  };

  return (
    <>
      <CommonHelmet title={'Category Add'} description={'Category Add'} />
      <Breadcrumb>
        <Breadcrumb.Item href="/category/list">{t('page.category_list_header')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('page.category_select')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-wrapper">
        <section id="content-wrapper">
          <div className="waste-main">
            <Row>
              <Col lg={12}>
                <div className="inner-box p-2">
                  <div className="edit-profile-form">
                    <h1 className="text-center category-select-title">
                      {t('page.select_category_to_add')}
                    </h1>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ isSubmitting, setFieldValue }) => (
                        <Form>
                          <Row className="justify-content-center h-100 align-items-center text-center">
                            <div className="d-flex justify-content-center text-start">
                              <Col sm={6}>
                                <BootstrapForm.Group>
                                  <BootstrapForm.Check
                                    type="radio"
                                    id="category_type_1"
                                    label="Parent Category"
                                    name="category_type"
                                    value="parent-category"
                                    onChange={(e) => {
                                      setFieldValue('category_type', e.target.value);
                                    }}
                                  />
                                  <BootstrapForm.Check
                                    type="radio"
                                    className="ms-2"
                                    id="category_type_2"
                                    label="Sub Category"
                                    name="category_type"
                                    value="sub-category"
                                    onChange={(e) => {
                                      setFieldValue('category_type', e.target.value);
                                    }}
                                  />
                                  <BootstrapForm.Check
                                    type="radio"
                                    id="category_type_3"
                                    label="Individual Category"
                                    name="category_type"
                                    value="individual-category"
                                    className="my-3"
                                    onChange={(e) => {
                                      setFieldValue('category_type', e.target.value);
                                    }}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                            </div>
                          </Row>

                          <div className="text-center mb-5 active-color">
                            <Button
                              variant="secondary"
                              onClick={() => navigate('/category/list')}
                              className="me-2">
                              {t('page.general_setting_cancel_link')}
                            </Button>
                            <Button type="submit" variant="primary" id="submit_btn">
                              {t('page.general_setting_save_button')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddCategory;

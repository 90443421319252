import { AdminBoostsService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetBoosts = (params, onSuccess, onError) => {
  return useQuery(
    ['boosts_list', [params]],
    () => {
      return AdminBoostsService.getList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useAddBoost = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBoostsService.add, {
    onSuccess,
    onError,
  });
};
const useDeleteBoost = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBoostsService.delete, {
    onSuccess,
    onError,
  });
};
const useChangeBoostStatus = (onSuccess, onError) => {
  return useMutation(AdminBoostsService.changeStatus, {
    onSuccess,
    onError,
  });
};
const useEditBoost = (onSuccess, onError = onDefaultError) => {
  return useMutation(AdminBoostsService.edit, {
    onSuccess,
    onError,
  });
};
const useGetBoost = (params, onSuccess, onError) => {
  return useQuery(
    ['boosts_detail', [params]],
    () => {
      return AdminBoostsService.get({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetBoosts,
  useAddBoost,
  useDeleteBoost,
  useChangeBoostStatus,
  useEditBoost,
  useGetBoost,
};

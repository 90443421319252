import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { PrivateLayout } from 'common/layouts';
import { Loader } from 'common';
import { loader } from 'store/features/loaderSlice';
import { loggedUser } from 'store/features/authSlice';

const PrivateRoute = () => {
  // Calling selector hook from redux, to get data from stores, like loader, login info
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  const location = useLocation();
  useEffect(() => {
    if (window.localStorage.isShowingError) {
      // toast.error(window.localStorage.isShowingError);
      window.localStorage.removeItem('isShowingError');
    }
  });
  /**
   * Checking if user is registered user or not
   */
  const isAdminLoggedIn = checkLoggedInUser.isAdminLoggedIn;
  return isAdminLoggedIn ? (
    <PrivateLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
